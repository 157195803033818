import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/login.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        redirect: '/appManagement',
        children:[
            {
                path: '/appManagement',
                name: 'AppManagement',
                component: ()=>import("../views/appManagement.vue")
            },
            {
                path: '/appManagementUser',
                name: 'AppManagementUser',
                component: ()=>import("../views/appManagementUser.vue")
            },
            {
                path: '/userEvents',
                name: 'UserEvents',
                component: ()=>import("../views/userEvents.vue")
            },
            {
                path: '/serviceLog',
                name: 'ServiceLog',
                component: ()=>import("../views/serviceLog.vue")
            },
            {
                path: '/abnormalLog',
                name: 'AbnormalLog',
                component: ()=>import("../views/abnormalLog.vue")
            },
            {
                path: '/events',
                name: 'Events',
                component: ()=>import("../views/events.vue")
            },
            {
                path: '/appGroup',
                name: 'AppGroup',
                component: ()=>import("../views/appGroup.vue")
            }
        ]
    }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
